<template>
  <div class="form-field">
    <label class="form-field__form-label">
      <span class="form-field__label-password mb-1">{{ labelText }}</span>
      <input
          class="form-field__form-input"
          :type="isVissiblePassword ? 'text' : 'password'"
          v-model="valueInput"
          @input="input(valueInput)"
          :class="{'form-input-error': validation}"
          :tabindex="tabindex"
      >
      <span class="form-field__form-icon" @click="isVissiblePassword = !isVissiblePassword">
      <v-icon :icon="isVissiblePassword ? 'password-show' :'password'" class="form-field__icon" v-if="icon"/>
    </span>
    </label>
  </div>
</template>

<script>

export default {
  name: "FormField",
  props: {
    labelText: String,
    value: String,
    validation: Boolean,
    icon: {
      type: Boolean,
      default: false
    },
    tabindex: Number,
  },
  data() {
    return {
      valueInput: this.value,
      isVissiblePassword: false
    }

  },
  methods: {
    input(value) {
      this.valueInput = value
      this.$emit('input', value)
    },
  }
}
</script>

<style lang="scss" scoped>
.form-field {

  &__form-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  &__form-input {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    width: 100%;
    min-height: 40px;
    border: 1px solid var(--grey-light-1);
    border-radius: 8px;
    padding: 0 48px 0 15px;
    position: relative;
    color: #8593AE;
    letter-spacing: 0.2em;

    &:focus {
      outline: none;
      border: 1px solid var(--blue-main);
    }
  }

  &__icon {
    position: absolute;
    top: 36px;
    right: 16px;
    cursor: pointer;
  }

  .form-input-error {
    border: 1px solid var(--red-main);
  }

  .text-error {
    color: var(--red-main);
  }
}
</style>