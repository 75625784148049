<template>
  <div class="login">
    <div class="login__texts">
      <div class="login__main-title">Forgot Password Request</div>
      <div class="login__welcome">Welcome to Adstellar. Please enter an email to send reset link to.</div>
    </div>
    <div class="login__container">
      <VOverlay v-if="statusForgotPassword === requestStatus.PENDING" dark>
        <VLoader dark />
      </VOverlay>
      <img class="login__logo" src="@/assets/img/auth/logo-login.svg" alt="">
      <form>
        <label class="login__form-label">
          <span class="login__label-name">Email</span>
          <input
            type="email"
            id="email"
            pattern=".+@globex\.com"
            class="login__form-input"
            v-model="email"
          >
        </label>
        <button class="login__form-btn" type="button" @click="submit">Send Link</button>
        <div class="login__accounts">
          <router-link to="/sign-up">Create an account</router-link>
          <router-link to="/login">Login</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from "@/js/api/index";
import { requestStatus, toasted } from '@/js/utils/constants';

export default {
  name: "ForgotPassword",
  metaInfo: {
    title: 'Forgot Password'
  },
  data() {
    return {
      requestStatus,
      statusForgotPassword: requestStatus.IDLE,
      email: '',
      password: '',
    };
  },
  mounted() {
    console.log(process.env);
  },
  methods: {
    async submit() {
      this.statusForgotPassword = requestStatus.PENDING;

      try {
        const result = await api.auth.forgotPassword(this.email);
        if(result?.data?.status) {
          this.$toasted.success(result.data.message);
          this.statusForgotPassword = requestStatus.SUCCESS;
        }
        this.statusForgotPassword = requestStatus.FAIL;
      } catch (err) {
        console.log('=> ERR', err);
        this.$toasted.error(toasted.text.error);
        this.statusForgotPassword = requestStatus.FAIL;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  font-family: var(--Montserrat);
  padding: 80px 24px 24px;
  background: center/cover no-repeat url(~@/assets/img/auth/bg-login.jpg) #ffffff;

  @media (max-width: 900px) {
    padding-top: 126px;
  }
  @media (max-width: 480px) {
    padding-top: 150px;
  }

  &__texts {
    width: 100%;
    max-width: 580px;
    text-align: center;
  }

  &__main-title {
    color: #ffffff;
    font-size: 42px;
    font-family: var(--DrukWide);
    line-height: 1.55;
    font-weight: bold;
    text-transform: uppercase;
    padding: 30px 0 20px;
  }

  &__welcome {
    color: #ffffff;
    font-size: 20px;
    line-height: 1.55;
    font-weight: 700;
    margin-bottom: 50px;
  }

  &__container {
    position: relative;
    width: 100%;
    max-width: 510px;
    padding: 25px 35px 40px 35px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    overflow: hidden;
  }

  &__logo {
    display: block;
    width: 83px;
    height: 90px;
    margin: 0 auto 40px;
  }

  &__form-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }

  &__label-name {
    display: block;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 5px;
  }

  &__form-input {
    color: #ffffff;
    border: none;
    border-bottom: 1px solid #ffffff;
    background-color: transparent;
    font-family: var(--DrukWide);
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    height: 23px;

    &:focus {
      outline: none;
    }
  }

  &__form-btn {
    color: #ffffff;
    background-color: #6980ff;
    border-radius: 8px;
    font-family: var(--Montserrat);
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    display: block;
    border: none;
    margin-bottom: 35px;
    cursor: pointer;
  }

  &__accounts {
    display: flex;
    justify-content: space-between;

    a {
      font-size: 16px;
      color: rgb(111, 207, 151);
      text-decoration: none;
    }
  }
}
</style>