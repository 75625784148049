<template>
  <div class="login">
    <div class="login__texts">
      <div class="login__main-title">Reset Password</div>
      <div class="login__welcome">Welcome to Adstellar. Please enter new password.</div>
    </div>
    <div class="login__container">
      <VOverlay v-if="statusResetPassword === requestStatus.PENDING" dark>
        <VLoader dark/>
      </VOverlay>
      <img class="login__logo" src="@/assets/img/auth/logo-login.svg" alt="">
      <form>
        <label class="login__form-label">
          <span class="login__label-name">Email</span>
          <input
              type="email"
              id="email"
              pattern=".+@globex\.com"
              class="login__form-input"
              v-bind:value="email"
              disabled="disabled"
          >
        </label>
        <label class="login__form-label">
          <span class="login__label-name">Password</span>
          <input class="login__form-input" type="password" v-model="password">
        </label>
        <label class="login__form-label">
          <span class="login__label-name">Password Confirmation</span>
          <input class="login__form-input" type="password" v-model="password_confirmation">
        </label>
        <button class="login__form-btn" type="button" @click="resetPassword">Reset</button>
        <div class="login__accounts">
          <router-link to="/sign-up">Create an account</router-link>
          <router-link to="/login">Login</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from "@/js/api/index";
import {requestStatus, toasted} from '@/js/utils/constants';

export default {
  name: "ResetPassword",
  metaInfo: {
    title: 'ResetPassword'
  },
  data() {
    return {
      requestStatus,
      statusResetPassword: requestStatus.IDLE,
      email: '',
      password: '',
      password_confirmation: '',
      token: this.$route.params.token,
    };
  },
  mounted() {
    if (this.$route.query?.email) {
      this.email = this.$route.query.email;
    }

    if(!this.$route.params.token) {
      this.$router.push('/login');
    }
  },
  methods: {
    async resetPassword() {
      if (this.password.length < 8) {
        this.$toasted.error('The password must be at least 8 characters');
        return;
      }
      if (this.password !== this.password_confirmation) {
        this.$toasted.error('Passwords not match');
        return;
      }
      this.statusResetPassword = requestStatus.PENDING;

      try {
        const data = {
          email: this.email,
          token: this.token,
          password: this.password,
          password_confirmation: this.password_confirmation,
        };
        const result = await api.auth.resetPassword(data);
        if (result.data.status) {
          this.$toasted.success(result.data.message);
          this.statusResetPassword = requestStatus.SUCCESS;
          setTimeout(() => {
            this.$router.push('/login');
          }, 2000);
        }
        this.statusResetPassword = requestStatus.FAIL;

      } catch (err) {
        console.error('=> reset password failed ');
        console.dir(err);
        const errorMessage = err?.response?.data?.err;
        let message = '';
        switch (errorMessage) {
          case 'passwords.user':
            message = 'Invalid user';
            break;
          case 'passwords.token':
            message = 'Invalid token';
            break;
           case 'passwords.throttled':
            message = 'Too many requests';
            break;
          default:
            message = toasted.text.error;
            break;

        }
        this.$toasted.error(message);
        this.statusResetPassword = requestStatus.FAIL;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  font-family: var(--Montserrat);
  padding: 80px 24px 24px;
  background: center/cover no-repeat url(~@/assets/img/auth/bg-login.jpg) #ffffff;

  @media (max-width: 900px) {
    padding-top: 126px;
  }
  @media (max-width: 480px) {
    padding-top: 150px;
  }

  &__texts {
    width: 100%;
    max-width: 580px;
    text-align: center;
  }

  &__main-title {
    color: #ffffff;
    font-size: 42px;
    font-family: var(--DrukWide);
    line-height: 1.55;
    font-weight: bold;
    text-transform: uppercase;
    padding: 30px 0 20px;
  }

  &__welcome {
    color: #ffffff;
    font-size: 20px;
    line-height: 1.55;
    font-weight: 700;
    margin-bottom: 50px;
  }

  &__container {
    position: relative;
    width: 100%;
    max-width: 510px;
    padding: 25px 35px 40px 35px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    overflow: hidden;
  }

  &__logo {
    display: block;
    width: 83px;
    height: 90px;
    margin: 0 auto 40px;
  }

  &__form-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }

  &__label-name {
    display: block;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 5px;
  }

  &__form-input {
    color: #ffffff;
    border: none;
    border-bottom: 1px solid #ffffff;
    background-color: transparent;
    font-family: var(--DrukWide);
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    height: 23px;

    &:focus {
      outline: none;
    }
  }

  &__form-btn {
    color: #ffffff;
    background-color: #6980ff;
    border-radius: 8px;
    font-family: var(--Montserrat);
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    display: block;
    border: none;
    margin-bottom: 35px;
    cursor: pointer;
  }

  &__accounts {
    display: flex;
    justify-content: space-between;

    a {
      font-size: 16px;
      color: rgb(111, 207, 151);
      text-decoration: none;
    }
  }
}
</style>