<template>
  <div class="new-password">
    <div class="new-password__container">
      <div class="new-password__title mb-5">
        <v-icon icon="logo" class="new-password__logo mb-2"/>
        <div>Adstellar</div>
      </div>
      <div class="new-password__subtitle mb-2">Set your password</div>
      <div class="new-password__text mb-3">Create a new, strong password that you <br> don't use for other websites.
      </div>
      <form class="new-password__form" @submit.prevent="submitForm">
        <FormField
            v-model="form.password"
            type="password"
            labelText="New password"
            :validation="$v.form.password.$error"
            class="mb-3"
            :icon="true"
        />
        <FormField
            v-model="form.password_confirmation"
            type="password"
            labelText="Confirm password"
            :validation="$v.form.password_confirmation.$error"
            class="mb-1"
            :icon="true"
        />
        <div v-if="$v.form.password_confirmation.$error" class="text-error">
          Passwords do not match
        </div>
        <button class="new-password__form-btn mt-3" type="submit">Set password and Log in</button>
      </form>
    </div>
  </div>
</template>

<script>
import {required, sameAs} from 'vuelidate/lib/validators'
import FormField from "@/js/components/common/form/FormField";

export default {
  name: "NewPassword",
  components: {FormField},
  data() {
    return {
      togglePassword: false,
      form: {
        password: '',
        password_confirmation: '',
      },
    }
  },
  validations: {
    form: {
      password: {required},
      password_confirmation: {
        sameAsPassword: sameAs('password')
      }
    }
  },
  methods: {
    async submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return
      const queryParams = new URLSearchParams(window.location.search)
      if(await this.$store.dispatch('auth/setPassword', {...this.form, token: queryParams.get('token')})) {
        console.log('Password was created')
        this.$router.push('/')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.new-password {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__container {
    max-width: 370px;
    width: 100%;
    padding: 40px 25px;
    background: var(--white-main);
    box-shadow: 0px 3px 10px 3px rgba(43, 72, 105, 0.11);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: var(--grey-dark);
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.5em;
  }

  &__subtitle {
    text-align: center;
    color: var(--black-main);
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--grey-dark);
    text-align: center;
  }

  &__logo {
    width: 120px;
    height: 80px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__form-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  &__form-input {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    width: 100%;
    min-height: 40px;
    border: 1px solid var(--grey-light-2);
    border-radius: 8px;
    padding: 0 15px;
    position: relative;

    &:focus {
      outline: none;
      border: 1px solid var(--blue-main);
    }
  }

  &__form-icon {
    position: absolute;
    top: 38px;
    right: 20px;
  }

  &__form-btn {
    width: 320px;
    height: 48px;
    background: var(--orange-main);
    border-radius: 8px;
    color: var(--white-main);
    border: 1px solid var(--orange-main);
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
  }

  .text-error {
    color: var(--red-main);
  }
}
</style>